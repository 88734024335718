

import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';
import indianFlag from '../assets/icons/flag-india-icon.svg';
import dropDownIcon from '../assets/icons/drop-down-icon.svg';
import axios from 'axios';
import Context from '../context/AppContext'; // Assuming your context is exported as Context from AppContext.js

export default function SignupStep1() {
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState('');
  const [error, setError] = useState(null);
  const { setMobileNumber: setGlobalMobileNumber } = useContext(Context); // Using useContext to access the context

  const handleChange = (e) => {
    setMobileNumber(e.target.value.trim()); // Trim whitespace from the input
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!mobileNumber) {
      alert('Please enter your mobile number.');
      return;
    }

    if (mobileNumber.replace(/\s/g, '') === '') {
      alert('Please enter a valid mobile number.');
      return;
    }

    setError(null); // Clear any previous errors
    try {
      const response = await axios.post('/api/auth/signup/send-otp', { phoneNumber: mobileNumber });
      // OTP sent successfully, proceed to verification
      alert(`Your OTP is: ${response.data.otp}`);
      navigate('/verify-signup', { state: { mobileNumber: mobileNumber } });
    } catch (error) {
      if (error.response) {
        if (error.response.status === 409) {
          // 409 Conflict - User already exists
          setError('A user with this mobile number already exists. Please log in instead.');
          // You might want to add a button or link to the login page here
        } else {
          setError('There was an error with your request. Please try again.');
        }
      } else if (error.request) {
        setError('No response received from the server. Please check your internet connection and try again.');
      } else {
        setError('An error occurred while processing your request. Please try again.');
      }
      console.error('Error:', error);
    }
  };

  return (
    <div className="flex bg-[#FFFFFF]">
      <div className="flex flex-col w-full max-w-[480px] mx-auto ">
        <div className="text-center mb-8">
          <div className="font-Montserrat text-2xl font-bold leading-10 text-[#1A1C1F]">Welcome</div>
          <div className="text-[#1A1C1F] font-medium text-[14px] mt-1">
            Welcome to <span className="text-[#E40443] font-semibold">DOOPER</span>, please enter your details
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col gap-4">
            <label htmlFor="mobileNumber" className="text-[#8D98A4] text-sm font-normal">Phone Number</label>
            <div className="flex items-center gap-2 border border-[#EEF0F3] rounded-md px-2">
              <img src={indianFlag} alt="India Flag" className="w-6 h-6" />
              <img src={dropDownIcon} alt="Dropdown Icon" className="w-4 h-4" />
              <div className="w-px h-3 bg-[#E3E6E8]" />
              <input
                type="text"
                id="mobileNumber"
                placeholder="Enter your phone number"
                className="flex flex-1 placeholder-[#5B6572] border-none outline-none text-sm font-medium h-11"
                value={mobileNumber}
                onChange={handleChange}
              />
            </div>
          </div>
          <PrimaryButton title="Send OTP" type="submit" />
          {error && <div className="text-red-500 text-sm mt-2">{error}</div>}
        </form>
        <div className="flex items-center justify-between ">
          <hr className="flex-1 bg-[#B8BFC7] h-[1px]" />
          <span className="text-[#B8BFC7] text-xs">Already have an account?</span>
          <hr className="flex-1 bg-[#B8BFC7] h-[1px]" />
        </div>
        <SecondaryButton title="Log In" action={() => navigate('/')} />
        <div className="flex flex-col mt-16">
          <div className="flex items-center text-sm font-normal text-[#5B6572]">
            <input type="checkbox" className="mr-2 h-4 w-4 border-2 border-[#4B465C]" />
            By signing up you agree to <a href="#" className="text-[#E40443]">Terms of use</a>
          </div>
          <div className="flex items-center text-sm font-normal text-[#5B6572]">
            <input type="checkbox" className="mr-2 h-4 w-4 border-2 border-[#4B465C]" />
            Get updates on WhatsApp
          </div>
        </div>
        <div className="text-center sm:mt-[158px] mt-[50px] text-[#5B6572] font-Montserrat text-[16px] font-normal sm:max-w-full max-w-[300px] mx-auto">
          Join the community of smart and experienced doctors. Signup to access your personalized dashboard, track your record or process and get informed by our services
        </div>
      </div>
    </div>
  );
}
