

// import React, { useState, useEffect } from "react";
// import Navbar from "../components/Navbar";
// import Footer from "../components/Footer";
// import { useNavigate } from "react-router-dom";
// import SecondaryButton from "../components/SecondaryButton";
// import Card from "../components/Card";
// import Footermobile from "../components/Footermobile";
// import axios from "axios";

// export default function MyBookingsUCMain() {
//   const navigate = useNavigate();
//   const [urgent, setUrgent] = useState('Urgent');
//   const [filterStatus, setFilterStatus] = useState('Upcoming');
//   const [bookings, setBookings] = useState([]);

//   useEffect(() => {
//     axios.get('/api/standaloneservice/vaccination')
//       .then(response => {
//         setBookings(response.data);
//         if (response.data.length > 0) {
//           setUrgent('Urgent'); // Set to 'Urgent' when data is fetched
//         }
//       })
//       .catch(error => {
//         console.error("There was an error fetching the bookings data!", error);
//       });
//   }, []);

//   const filterBookings = () => {
//     return bookings.filter(booking => 
//       (urgent === 'Standalone Service') && (filterStatus === 'Upcoming' || filterStatus === booking.status)
//     );
//   };

//   return (
//     <>
//       <div className="min-h-screen flex flex-col justify-between">
//         <div className="hidden sm:block">
//           <Navbar />
//         </div>

//         <div className="flex flex-col gap-4 sm:px-28 px-5 sm:pb-0 pb-36">
//           <div className="flex justify-between items-center py-6">
//             <div className="flex items-center space-x-2">
//               <img 
//                 src="https://static.thenounproject.com/png/3318548-200.png" 
//                 className="sm:hidden w-10 h-9 font-extrabold"
//                 alt="Bookings Icon"
//               />
//               <p className="text-[#1A1C1F] text-lg sm:text-2xl font-bold leading-8">My Bookings</p>
//             </div>
//             <button className="text-center px-6 py-3 rounded-md sm:w-52 font-Montserrat font-semibold text-sm sm:text-base text-primary01 leading-relaxed bg-primary07">
//               +Book Now
//             </button>
//           </div>

//           <div className="flex justify-between items-center border-b pb-4">
//             <ul className="flex flex-1 space-x-1 font-Montserrat text-sm sm:text-base text-[#8D98A4] font-semibold">
//               <li
//                 className={`px-4 cursor-pointer ${
//                   urgent === 'Urgent' ? "border-b-2 pb-2 text-black border-[#E40443]" : ""
//                 }`}
//                 onClick={() => setUrgent('Urgent')}
//               >
//                 Urgent
//               </li>
//               <li
//                 className={`px-4 cursor-pointer ${
//                   urgent === 'Non-Urgent' ? "border-b-2 pb-2 text-black border-[#E40443]" : ""
//                 }`}
//                 onClick={() => setUrgent('Non-Urgent')}
//               >
//                 Non-Urgent
//               </li>
//               <li
//                 className={`px-4 cursor-pointer ${
//                   urgent === 'Standalone Service' ? "border-b-2 pb-2 text-black border-[#E40443]" : ""
//                 }`}
//                 onClick={() => setUrgent('Standalone Service')}
//               >
//                 Standalone Service
//               </li>
//             </ul>
//             <div className="text-sm sm:text-base">
//               Sort By
//               <select
//                 className="outline-none rounded-md bg-transparent ml-2"
//                 onChange={(e) => setFilterStatus(e.target.value)}
//               >
//                 <option value="All">All</option>
//                 <option value="Pending">Pending</option>
//                 <option value="Completed">Completed</option>
//               </select>
//             </div>
//           </div>

//           <div className="border border-[#B3B3B3] bg-[#EEEFEF] w-44 px-2 py-1 mb-4 rounded-md">
//             <select 
//               className="w-full outline-none bg-transparent font-semibold font-Montserrat text-sm sm:text-base"
//               onChange={(e) => setFilterStatus(e.target.value)}
//             >
//               <option value="Upcoming">Upcoming</option>
//               <option value="Completed">Completed</option>
//               <option value="Cancelled">Cancelled</option>
//             </select>
//           </div>

//           <div className="flex flex-wrap gap-7 mb-8">
//             {filterBookings().map((booking, index) => (
//               <div key={index}>
//                 <Card
//                   type={1} 
//                   status="DHA Assigned" 
//                   bookingId={booking._id}
//                   patientName={booking.patientName}
//                   symptoms={booking.symptoms}
//                   createdAt={booking.createdAt}
//                   onClickAction={() => navigate(`/bookings/standalone-booking-vaccination-details/${booking._id}`)}
//                 />
//               </div>
//             ))}
//           </div>
//         </div>

//         <Footer />
//         <Footermobile/>
//       </div>
//     </>
//   );
// }


import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import SecondaryButton from "../components/SecondaryButton";
import Card from "../components/Card";
import Footermobile from "../components/Footermobile";
import axios from "axios";

export default function MyBookingsUCMain() {
  const navigate = useNavigate();
  const [urgent, setUrgent] = useState('Urgent');
  const [filterStatus, setFilterStatus] = useState('Upcoming');
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    axios.get('/api/standaloneservice/vaccination')
      .then(response => {
        if (Array.isArray(response.data)) {
          setBookings(response.data);
          if (response.data.length > 0) {
            setUrgent('Urgent'); // Set to 'Urgent' when data is fetched
          }
        } else {
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch(error => {
        console.error("There was an error fetching the bookings data!", error);
      });
  }, []);

  const filterBookings = () => {
    if (!Array.isArray(bookings)) {
      console.error("Bookings is not an array:", bookings);
      return [];
    }
    return bookings.filter(booking => 
      (urgent === 'Standalone Service') && (filterStatus === 'Upcoming' || filterStatus === booking.status)
    );
  };

  return (
    <>
      <div className="min-h-screen flex flex-col justify-between">
        <div className="hidden sm:block">
          <Navbar />
        </div>

        <div className="flex flex-col gap-4 sm:px-28 px-5 sm:pb-0 pb-36">
          <div className="flex justify-between items-center py-6">
            <div className="flex items-center space-x-2">
              <img 
                src="https://static.thenounproject.com/png/3318548-200.png" 
                className="sm:hidden w-10 h-9 font-extrabold"
                alt="Bookings Icon"
              />
              <p className="text-[#1A1C1F] text-lg sm:text-2xl font-bold leading-8">My Bookings</p>
            </div>
            <button className="text-center px-6 py-3 rounded-md sm:w-52 font-Montserrat font-semibold text-sm sm:text-base text-primary01 leading-relaxed bg-primary07">
              +Book Now
            </button>
          </div>

          <div className="flex justify-between items-center border-b pb-4">
            <ul className="flex flex-1 space-x-1 font-Montserrat text-sm sm:text-base text-[#8D98A4] font-semibold">
              <li
                className={`px-4 cursor-pointer ${
                  urgent === 'Urgent' ? "border-b-2 pb-2 text-black border-[#E40443]" : ""
                }`}
                onClick={() => setUrgent('Urgent')}
              >
                Urgent
              </li>
              <li
                className={`px-4 cursor-pointer ${
                  urgent === 'Non-Urgent' ? "border-b-2 pb-2 text-black border-[#E40443]" : ""
                }`}
                onClick={() => setUrgent('Non-Urgent')}
              >
                Non-Urgent
              </li>
              <li
                className={`px-4 cursor-pointer ${
                  urgent === 'Standalone Service' ? "border-b-2 pb-2 text-black border-[#E40443]" : ""
                }`}
                onClick={() => setUrgent('Standalone Service')}
              >
                Standalone Service
              </li>
            </ul>
            <div className="text-sm sm:text-base">
              Sort By
              <select
                className="outline-none rounded-md bg-transparent ml-2"
                onChange={(e) => setFilterStatus(e.target.value)}
              >
                <option value="All">All</option>
                <option value="Pending">Pending</option>
                <option value="Completed">Completed</option>
              </select>
            </div>
          </div>

          <div className="border border-[#B3B3B3] bg-[#EEEFEF] w-44 px-2 py-1 mb-4 rounded-md">
            <select 
              className="w-full outline-none bg-transparent font-semibold font-Montserrat text-sm sm:text-base"
              onChange={(e) => setFilterStatus(e.target.value)}
            >
              <option value="Upcoming">Upcoming</option>
              <option value="Completed">Completed</option>
              <option value="Cancelled">Cancelled</option>
            </select>
          </div>

          <div className="flex flex-wrap gap-7 mb-8">
            {filterBookings().map((booking, index) => (
              <div key={index}>
                <Card
                  type={1} 
                  status="DHA Assigned" 
                  bookingId={booking._id}
                  patientName={booking.patientName}
                  symptoms={booking.symptoms}
                  createdAt={booking.createdAt}
                  onClickAction={() => navigate(`/bookings/standalone-booking-vaccination-details/${booking._id}`)}
                />
              </div>
            ))}
          </div>
        </div>

        <Footer />
        <Footermobile/>
      </div>
    </>
  );
}


