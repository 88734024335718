


import React, { useState,useRef,  useEffect, useContext } from "react";
import axios from 'axios';
import PrimaryButton from '../components/PrimaryButton';
import add from '../assets/icons/plus-icon.svg';
import deleteIcon from '../assets/icons/close.svg';
import UploadIcon from '../assets/icons/upload-file-icon.svg';
import Context from '../context/AppContext';

export default function ServiceBookingModal(props) {
  const { mobileNumber } = useContext(Context);
  const fileRef = useRef();
  const [symptoms, setSymptoms] = useState('');
  const [d, setD] = useState([{ id: 1, title: 'fever' }, { id: 2, title: 'Cold' }, { id: 3, title: 'Flu' }, { id: 4, title: 'etc' }]);
  const [selectedSymptoms, setSelectedSymptoms] = useState([]);
  const [patientName, setPatientName] = useState('');
  const [patientsNote, setPatientsNote] = useState('');
  const [videoFile, setVideoFile] = useState(null);

  const handleFileChange = (event) => {
    setVideoFile(event.target.files[0]);
  };

  const handleAddSymptom = () => {
    if (symptoms) {
      setSelectedSymptoms([...selectedSymptoms, symptoms]);
      setSymptoms('');
    }
  };

  const handleDeleteSymptom = (symptom) => {
    setSelectedSymptoms(selectedSymptoms.filter(s => s !== symptom));
  };

  const handleSubmit = async () => {
    try {
      // Upload the video file first
      const formData = new FormData();
      formData.append('file', videoFile);
      const uploadResponse = await axios.post('/api/urgentbooking/upload-video', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      const videoNote = uploadResponse.data.file.filename;

      // Create the urgent case
      const caseData = {
        symptoms: selectedSymptoms,
        patientName,
        patientsNote,
        videoNote,
        mobileNumber // Include mobileNumber in the request body
      };

      await axios.post('/api/urgentbooking/urgent-cases', caseData);
      props.action(true);
      props.onClose(false);
    } catch (error) {
      console.error('Error submitting the form', error);
    }
  };

  return (
    <div className='fixed top-0 left-0 right-0 bottom-0'>
      <div className='fixed top-0 left-0 right-0 bottom-0 bg-black/30' onClick={() => { props.onClose(false) }}></div>
      <div className='fixed top-1/2 left-1/2 -translate-x-[50%] -translate-y-[50%] flex flex-col gap-[32px] font-Montserrat bg-white sm:w-[585px] w-[285px] rounded-[8px] p-[32px]'>
        <h5 className='text-[22px] sm:text:[28px] text-[#1A1C1F] font-[600] font-bold leading-[38px]'>Book {props.bookingType} Case</h5>
        <div className='flex flex-col items-start'>
          <div className='flex flex-col gap-[24px] w-full'>
            <div>
              <label className='text-[#8D98A4] text-xs w-full font-Montserrat'>Symptoms</label>
              <div className='flex justify-between items-center w-full rounded-[8px] border border-[#EEF0F3] py-[6px] pl-[16px] pr-[6px] mb-[12px]'>
                <input type='text' value={symptoms} onChange={(e) => setSymptoms(e.target.value)} placeholder='Add symptoms' className='text-[14px] font-[500] leading-[116%] outline-none bg-transparent' />
                <img src={add} className='cursor-pointer bg-primary01 w-[32px] p-[8px] rounded-[8px] -ml-[40px]' onClick={handleAddSymptom} />
              </div>
              <div className='grid grid-cols-3 sm:grid-cols-4 gap-[8px]'>
                {selectedSymptoms.map((symptom, index) => (
                  <div key={index} className='cursor-pointer flex gap-[4px] items-center w-fit bg-[#F4F4F4]/40 border border-[#EEF0F3] px-[12px] py-[8px] rounded-[8px]'>
                    <p className='text-[12px] text-[#5B6572] font-[500] leading-[116%]'>{symptom}</p>
                    <img className='w-[14px]' src={deleteIcon} onClick={() => handleDeleteSymptom(symptom)} />
                  </div>
                ))}
              </div>
            </div>
            <div className='flex flex-col gap-[8px]'>
              <label className='text-[#8D98A4] text-xs font-Montserrat'>Select Patient</label>
              <div className='flex items-center border border-[#EEF0F3] px-[16px] py-[12px] rounded'>
                <input type='text' value={patientName} onChange={(e) => setPatientName(e.target.value)} placeholder='Patient Name' className='text-[14px] text-[#5B6572] font-[500] leading-[116%] w-full outline-none' />
              </div>
            </div>
            <div className='flex flex-col gap-[8px]'>
              <label className='text-[#8D98A4] text-xs font-Montserrat'>Patients Note*</label>
              <div className='flex border border-[#EEF0F3] rounded'>
                <textarea value={patientsNote} onChange={(e) => setPatientsNote(e.target.value)} placeholder='Add description Here' className='w-full min-h-[88px] px-[20px] py-[16px] rounded-none focus:outline-none'></textarea>
              </div>
            </div>
            <div className='flex flex-col gap-[8px]'>
              <label className='text-[#8D98A4] text-xs font-Montserrat'>Video Note*</label>
              <div
                onClick={() => { fileRef.current.click() }}
                className="cursor-pointer flex flex-col justify-center items-center border border-[#EEF0F3] px-[20px] py-[16px] rounded-[8px]">
                <input
                  type="file"
                  ref={fileRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <img src={UploadIcon} alt="" className="mx-auto" />
                <p className='text-[#5B6572] text-[14px] font-[500] leading-[116%]'>Upload</p>
              </div>
            </div>
          </div>
          </div>
      
        <div className=''> 
          <PrimaryButton title="Confirm Booking"  action={handleSubmit} />
          </div>
        
      </div>
    </div>
  );
}


