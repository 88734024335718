import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import arr from "../assets/icons/arrow-left-black-icon.svg";
import leftArrowIcon from "../assets/icons/left-arrow-icon.svg";
import rightArrowIcon from "../assets/icons/right-arrow-icon.svg";
import emptyNotificationIcon from "../assets/icons/notifications-empty.svg";
import av1 from "../assets/images/Avatar-notification-1.svg";
import av2 from "../assets/images/Avatar-notification-2.svg";
import av3 from "../assets/images/Avatar-notification-3.svg";
import av4 from "../assets/images/Avatar-notification-4.svg";
import av5 from "../assets/images/Avatar-notification-3.svg";
import NotificationIcon from "../assets/icons/notification-bell-icon.svg"
import PhoneIcon from "../assets/icons/phone-footer-icon.svg"


const Notifications = () => {
  const navigate = useNavigate();
  const data = [
    {
      image: av1,
      title: "Loerem Ipsum dolor sit amet",
      date: "25-07-2023",
      time: "05:10 AM",
    },
    {
      image: av2,
      title: "Loerem Ipsum dolor sit amet",
      date: "25-07-2023",
      time: "05:10 AM",
    },
    {
      image: av3,
      title: "Loerem Ipsum dolor sit amet",
      date: "25-07-2023",
      time: "05:10 AM",
    },
    {
      image: av2,
      title: "Loerem Ipsum dolor sit amet",
      date: "25-07-2023",
      time: "05:10 AM",
    },
    {
      image: av4,
      title: "Loerem Ipsum dolor sit amet",
      date: "25-07-2023",
      time: "05:10 AM",
    },
    {
      image: av5,
      title: "Loerem Ipsum dolor sit amet",
      date: "25-07-2023",
      time: "05:10 AM",
    },
    {
      image: av4,
      title: "Loerem Ipsum dolor sit amet",
      date: "25-07-2023",
      time: "05:10 AM",
    },
  ];
  const [notifications, setNotifications] = useState(data);

  return (
    <div className="sm:w-[100vw] h-[100vh]">
      <div ></div>
      <div className="w-[100vw]  bg-white">
        <div className="">
          <main className="sm:mx-[120px] pt-[24px] pb-[48px]  ">
            <div
              className=" flex justify-between cursor-pointer font-Montserrat flex-row font-[700] text-[22px] h-[32px] sm:w-[1077px] pb-[8px]"
              onClick={() => {
                navigate("/home");
              }}
            >
            <div className="flex mt-[10px]">
              <img
                src={arr}
                alt="arr"
                srcSet=""
                className=" hidden sm:block h-[24px] w-[24px] mt-1 mr-[8px]"
              />
              <img
                src={leftArrowIcon}
                alt="arr"
                srcSet=""
                className="sm:hidden h-[24px] w-[24px] mt-1 mr-[8px]"
              />
              Notifications
              </div>
              <div className="flex sm:hidden mt-2">
                <img src={NotificationIcon} 
                  className="h-[20px] w-[20px] mt-[10px]"
                />
                <img src={PhoneIcon} 
                  className="h-[40px] w-[40px]"
                />
              </div>
            </div>
            <hr className="mt-[28px]" />
            <div className="flex flex-row items-end justify-end sm:mt-[23px] mt-[10px] ">
              <div className="font-sans text-center text-[12px] font-[600] underline text-[#E40443] mr-[20px]">
                Mark all as read
              </div>
              <div
                className="font-sans text-center text-[12px] font-[600] underline text-[#E40443] mr-[20px]"
                onClick={() => {
                  setNotifications([]);
                }}
              >
                Clear all
              </div>
              <h1 className="hidden sm:block font-sans text-center text-[15px] font-[400] text-[#4B465C] mr-[33px] opacity-[50%]">
                1-10 of 10
              </h1>
              <img src={leftArrowIcon} alt="rl" className="hidden sm:block"/>
              <img src={rightArrowIcon} alt="rl" className="hidden sm:block"/>
            </div>
            {/* <hr />   */}
            { notifications.length > 0 ?
              <>
              {notifications.map((notification) => {
                return (
                <>
                  <div className="flex flex-row px-[14px] py-[20px] ">
                      <img
                        src={notification.image}
                        alt=""
                        className="rounded-full ml-[20px]"
                      />
                      <h2 className="ml-5 font-Montserrat text-[16px] font-medium text-[#5B6572] mt-1">
                        {notification.title}
                      </h2>
                      <h3 className="hidden sm:block ml-[720px] pt-[6px] font-sans font-normal text-[#4B465C] text-[13px] opacity-[50%]">
                        {notification.date}
                      </h3>
                      <h4 className="hidden sm:block ml-[35px] mr-[20px] pt-[6px] font-sans font-normal text-[#4B465C] text-[13px] opacity-[50%] ">
                        {notification.time}
                      </h4>
                    </div>
                    {/* <hr /> */}
                  </>
                    );
                  })}
                <hr />
             </>:
              <div className="flex flex-col jusitfy-center items-center gap-[96px] p-[50px] lg:p-[100px]">
              <img src={emptyNotificationIcon} alt="Empty" />
              <p className="sm:text-[20px] text-[15px] font-[600] leading-[120%]">"No new notifications at the moment. Stay tuned for updates!"</p>
            </div>
            }
            
          </main>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
