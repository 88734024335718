///practise1
import React from "react";
import cal from "../assets/icons/calender-icon.svg";
import clock from "../assets/icons/clock.svg";
import pro from "../assets/images/Patient-picture.svg";
import { Link } from "react-router-dom";

export default function Card(props) {
  const { bookingId, patientName, vaction, createdAt, type, status, onClickAction } = props;

  // Format the date
  const formattedDate = new Date(createdAt).toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });

  return (
    <>
      <div className="sm:w-[380px] border-[1px] bg-white border-[#D3D3D3] rounded-xl" onClick={onClickAction}>
        <div className="flex flex-col border-b-[1px] border-[#E4E4E4] px-[16px] py-[14px] top-0  justify-center items-start">
          <div className="flex flex-row sm:w-[348px] gap-[8px]">
            <h1 className="text-[#E40443] font-Montserrat text-[18px] font-[600] pr-[80px]">
              #{bookingId.slice(-6)}
            </h1>
            <h2 className="flex font-Montserrat text-[12px] font-[500] text-[#5B6572] pt-1 ">
              <img
                src={cal}
                alt=""
                className="h-[14px] w-[14px] mt-[2px] mr-[8px]"
              />
              {formattedDate}
            </h2>
            <h2 className="flex font-Montserrat text-[12px] font-[500] text-[#5B6572] pt-1">
              <img
                src={clock}
                alt=""
                className="h-[14px] w-[14px] mt-[2px] mr-[8px]"
              />
              09:00AM
            </h2>
          </div>
          <div className="text-[#E40443] font-Montserrat  text-[12px] font-[600] tracking-[1px]">
            {vaction && vaction.join(' | ')}
          </div>
        </div>
        <div className="flex gap-[16px] justify-center items-center py-[14px] px-[16px] border-b-[1px] border-[#E4E4E4] ">
          <img src={pro} alt="pro" className="w-[48px]" />
          <div className="font-Montserrat">
            <div className="flex justify-between items-center">
              <h1 className="font-Montserrat text-[18px] font-[600]">
                {patientName}
              </h1>
              {props.nextStep && <h2 className="flex justify-center items-center text-white text-[10px] font-[500] leading-[116%] bg-primary01 py-[2px] px-[8px] rounded-[2px]">{props.nextStep}</h2>}
            </div>
            <div className="text-[12px] font-[500] text-[#5B6572]">Age : 32 | Gender: Male | Blood Group: O+ Height : 6” 3 inches | Weight : 76</div>
          </div>
        </div>
        <div className="py-[13px] px-[16px] h-[56px]">
          {props.type == 1 && <>
            {props.status === "Lab Assigned" && <div className="text-white text-[12px] font-[500] leading-[116%] flex justify-cener items-center w-fit bg-[#19B4D7] py-[7px] px-[16px] rounded-[24px]">
              {props.status}
            </div>}
            {props.status === "Doctor Assigned" && <div className="text-white text-[12px] font-[500] leading-[116%] flex justify-cener items-center w-fit bg-[#1A869D] py-[7px] px-[16px] rounded-[24px]">
              {props.status}
            </div>}
            {props.status === "DHA Assigned" && <div className="text-white text-[12px] font-[500] leading-[116%] flex justify-cener items-center w-fit bg-[#19B4D7] py-[7px] px-[16px] rounded-[24px]">
              {props.status}
            </div>}
            {props.status === "Pharmacy Assigned" && <div className="text-white text-[12px] font-[500] leading-[116%] flex justify-cener items-center w-fit bg-[#1A869D] py-[7px] px-[16px] rounded-[24px]">
              {props.status}
            </div>}
            {props.status === "DHA Service Started" && <div className="text-white text-[12px] font-[500] leading-[116%] flex justify-cener items-center w-fit bg-[#437BE9] py-[7px] px-[16px] rounded-[24px]">
              {props.status}
            </div>}
            {props.status === "Lab test suggested" && <div className="text-white text-[12px] font-[500] leading-[116%] flex justify-cener items-center w-fit bg-[#610ECE] py-[7px] px-[16px] rounded-[24px]">
              {props.status}
            </div>}
            {props.status === "Prescription added" && <div className="text-white text-[12px] font-[500] leading-[116%] flex justify-cener items-center w-fit bg-[#315BAE] py-[7px] px-[16px] rounded-[24px]">
              {props.status}
            </div>}
          </>}
          {props.type == 2 && <div className="text-white text-[12px] font-[500] leading-[116%] flex justify-cener items-center w-fit bg-[#41B079] py-[7px] px-[16px] rounded-[24px]">
            Completed
          </div>}
          {props.type == 3 && <div className="text-white text-[12px] font-[500] leading-[116%] flex justify-cener items-center w-fit bg-[#FF0000] py-[7px] px-[16px] rounded-[24px]">
            Cancelled
          </div>}
        </div>
      </div>
    </>
  );
}
